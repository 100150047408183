import { gaTrack } from './gaTrack'
import { ViewItemParameters } from '../track'
import { ViewItemMap } from '../const'
import { BRAND } from '@shopify/const'
import { formatPrice, getCurrencyCode, getPageGroup } from '../utils'

export const viewItem = ({
  event,
  product,
  variant,
  locale,
  page_group,
  discountPrice,
}: ViewItemParameters) => {
  const currencyCode = getCurrencyCode([product])
  const priceWithOutSymbol: string = formatPrice(
    variant?.price,
    currencyCode,
    locale
  )
  gaTrack({ event_parameters: null })
  gaTrack({
    event: ViewItemMap[event],
    page_group: getPageGroup({ page_group, sku: variant.sku }),
    ecommerce: {
      currency: currencyCode,
      value: discountPrice || priceWithOutSymbol, //产品折后价格
      items: [
        {
          item_id: variant.sku,
          item_name: product.name,
          item_brand: BRAND,
          item_category: product.productType,
          item_variant: variant.name,
          price: priceWithOutSymbol,
        },
      ],
    },
  })
}
