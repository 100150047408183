import cn from 'classnames'
import React, {
  forwardRef,
  ButtonHTMLAttributes,
  JSXElementConstructor,
  useRef,
} from 'react'
import mergeRefs from 'react-merge-refs'
import styles from './Button.module.css'
import { LoadingDots } from '@components/ui'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  className?: string
  variant?:
    | 'primary'
    | 'error'
    | 'primary-white'
    | 'primary-blue'
    | 'secondary'
    | 'secondary-white'
    | 'link'
    | 'link-white'
    | 'video'
    | 'video-white'
    | string
  active?: boolean
  type?: 'submit' | 'reset' | 'button'
  Component?: string | JSXElementConstructor<any>
  width?: string | number
  loading?: boolean
  disabled?: boolean
  border?: boolean
  s?: any
}

const Button: React.FC<ButtonProps> = forwardRef((props, buttonRef) => {
  const {
    className,
    variant = '',
    children,
    active,
    width,
    loading = false,
    disabled = false,
    style = {},
    Component = 'a',
    // eslint-disable-next-line no-unused-vars
    border = false,
    ...rest
  } = props
  const ref = useRef<typeof Component>(null)
  const s = { ...styles }
  const rootClassName = cn(
    s.btn,
    {
      [s.primary]: variant === 'primary',
      [s['primary-white']]: variant === 'primary-white',
      [s['primary-blue']]: variant === 'primary-blue',
      [s.secondary]: variant === 'secondary',
      [s['secondary-white']]: variant === 'secondary-white',
      [s.link]: variant === 'link',
      [s['link-white']]: variant === 'link-white',
      [s.video]: variant === 'video',
      [s['video-white']]: variant === 'video-white',
      [s.error]: variant === 'error',
      [s.disabled]: disabled,
    },
    className
  )

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      className={rootClassName}
      disabled={disabled || loading}
      tabIndex={'onClick' in rest ? '0' : '-1'}
      role={'onClick' in rest ? 'button' : ''}
      onKeyPress={
        'onClick' in rest
          ? (e: any) => {
              if (e.key == 'Enter') {
                rest.onClick && rest.onClick(e)
              }
            }
          : () => {}
      }
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {loading ? <LoadingDots /> : children}
    </Component>
  )
})

Button.displayName = 'Button'

export default Button
