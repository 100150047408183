import type { ProductVariant, VariantCoupon } from '@commerce/types/product'
import { useMemo } from 'react'
import { atobID } from '@lib/utils/tools'

enum CouponType {
  hideChannel = 'wsch',
}

// 优化代码：简化条件判断和逻辑
export function getCouponFromVariant(
  variant: ProductVariant | undefined,
  channel?: string
) {
  const coupons =
    variant?.metafields?.coupons?.filter((coupon: VariantCoupon) => {
      const isActive =
        (coupon.ends_at === null ||
          new Date(coupon.ends_at).getTime() > new Date().getTime()) &&
        new Date(coupon.starts_at).getTime() <= new Date().getTime() &&
        coupon.variant_shopify_id === +atobID(variant?.id)
      return (
        isActive &&
        (channel
          ? coupon.title.toLocaleLowerCase().includes(channel)
          : !coupon.title.toLocaleLowerCase().includes(CouponType.hideChannel))
      )
    }) || []

  return coupons[0]
}

// 使用React Hook优化组件状态管理
export function useCoupon({
  variant,
  channel,
}: {
  variant: ProductVariant | undefined
  channel?: string
}) {
  const coupon = useMemo(
    () => getCouponFromVariant(variant, channel),
    [variant, channel]
  )

  return {
    coupon,
  }
}
