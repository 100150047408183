import { CommerceParameters } from '../track'
import { EcommerceMetaMap } from '../const'
import { metaTrack } from './metaTrack'
import { atobID } from '@lib/utils/tools'
import { GTMtagsToCollection } from '@lib/utils/thirdparty'

export const metaCommerce = ({
  event,
  product,
  sku,
}: CommerceParameters): void => {
  const p = Array.isArray(product) ? product[0] : product
  const variant =
    p.variants.length === 1
      ? p.variants[0]
      : p.variants.find((v) => v.sku === sku)

  metaTrack(EcommerceMetaMap[event], {
    value: variant?.price,
    num_items: 1,
    currency: p?.price?.currencyCode,
    content_type: 'product_group',
    content_ids: atobID(variant?.id),
    content_category: GTMtagsToCollection(variant?.tags || p?.tags),
  })
}
