import { gaTrack } from './gaTrack'
import { ViewListParameters, ViewListItem, ViewListProduct } from '../track'
import { PageViewGa3Map, PageViewGa4Map } from '../const'
import { BRAND } from '@shopify/const'
import { getPageGroup, formatPrice, getCurrencyCode } from '../utils'

export const viewList = ({
  event,
  product,
  page_group,
  handle,
  sku,
  login_status = 'non-member',
  position,
  category,
  item_list_name,
  locale,
}: ViewListParameters) => {
  const products = Array.isArray(product) ? product : [product]
  const currencyCode = getCurrencyCode(products)
  const items: ViewListItem[] = []
  const viewListProducts: ViewListProduct[] = []
  let totalProductPrice = 0

  products.forEach((p, idx) => {
    const variant =
      p?.variants?.length === 1
        ? p?.variants[0]
        : idx === 0
        ? p?.variants?.find((v: any) => v.sku === sku)
        : p?.variants?.[0]
    const price = p?.isFreeGift ? 0 : variant?.price || p?.price?.value
    const priceWithOutSymbol: string = formatPrice(price, currencyCode, locale)

    totalProductPrice += price

    viewListProducts.push({
      name: p?.name,
      id: variant?.sku,
      category,
      categoryName: p?.productType || '',
      brand: BRAND,
      variant: variant?.name,
      index: idx,
      price: priceWithOutSymbol,
      quantity: 1,
    })
    items.push({
      item_list_id: variant?.sku,
      item_list_name: `${item_list_name}${handle}` || p?.name,
      item_brand: BRAND,
      item_category: category || p.productType || '',
      item_variant: variant?.name,
      index: idx,
      price: priceWithOutSymbol,
      quantity: 1,
    })
  })

  gaTrack({ event_parameters: null })
  gaTrack({
    event: 'ga4Event',
    event_name: PageViewGa4Map[event], //如果是加购,则传add_to_cart
    event_parameters: {
      page_group: getPageGroup({ page_group, sku, handle }),
      position, //位置标记。如果是listing的底部悬浮按钮，传Product Detail Page_Sticky;如果是listing的首屏按钮，传Product Detail Page_Checkout；如果是侧边栏，传Cart Pop Up；如果是活动页/首页，则传该模块的小标题（该模块有ref参数则传ref参数）
      info: login_status, //用户登录状态member、non-member（GTM上线login_status事件参数后删掉或可覆盖）
      currency: currencyCode,
      value: formatPrice(totalProductPrice, currencyCode, locale), //折后总价
      item_list_name: `${item_list_name}${handle}`,
      items,
    },
  })
}
