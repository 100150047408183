export enum EcommerceGa3Map {
  buynow = 'BuyNow',
  add2cart = 'Added Product',
}

export enum EcommerceGa4Map {
  buynow = 'begin_checkout',
  add2cart = 'add_to_cart',
}

export enum EcommerceMetaMap {
  buynow = 'InitiateCheckout',
  add2cart = 'AddToCart',
}

export enum ClickMap {
  image_box = 'small_carousel_switch',
  collections_banner = 'collections_banner',
  switch = 'switch',
  select_item = 'select_item',
  lp_button = 'lp_button',
}

export enum ViewItemListMap {
  view_item_list = 'view_item_list',
}

export enum PageViewGa3Map {
  page_view = 'page_view',
  view_item_list = 'view_item_list',
}

export enum PageViewGa4Map {
  page_view = 'page_view',
  view_item_list = 'view_item_list',
}

export enum ViewItemMap {
  viewitem = 'view_item',
}
