import cn from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import s from './NewcomerSubscribePopup.module.css'
import { Picture } from '@components/ui'
import Cookies from 'js-cookie'
import SubscribeFrom from 'templates/Common/SubscribeFrom'

interface NewcomerSubscribePopupMetafields {
  pop_title: string
  pop_desc: string
  img_url: string
  submit_btn_txt: string
  dealsType: string
  codeText: string
  copy: string
  noteText: string
  hidePop: boolean
}

interface NewcomerSubscribePopupProps {
  newcomerSubscribePopupMetafields: NewcomerSubscribePopupMetafields
  className?: string
  showNewPop: boolean | string
  id?: string
}

const NewcomerSubscribePopup: FC<NewcomerSubscribePopupProps> = ({
  newcomerSubscribePopupMetafields,
  className,
  id = '',
  showNewPop = true,
  // @ts-ignore
  shopCommon,
}) => {
  const [show, setShow] = useState(false)
  const rootClassName = cn(className, s.newcomerSubscribePopup, {
    ['hidden']: !show,
  })

  const handlePopupOpen = useCallback(() => {
    const showNewPopState = showNewPop === 'false'
    // 超过两屏弹出弹窗
    if (
      window.scrollY > window.innerHeight &&
      !newcomerSubscribePopupMetafields?.hidePop
    ) {
      // 用户如果订阅，种一个有效期1年的标识，1年后标识过期，再次访问会重新弹窗
      // 用户如果未订阅，种一个有效期30天的标识，30天后标识过期，再次访问会重新弹窗
      if (
        !Cookies.get('newpopup_expires30') &&
        !Cookies.get('newpopup_expires365') &&
        !showNewPopState
      ) {
        setShow(true)
      }
    }
  }, [newcomerSubscribePopupMetafields, showNewPop])

  const handlePopupClose = useCallback(() => {
    Cookies.set('newpopup_expires30', 'true', {
      expires: 30,
      Domain: window.location.hostname,
      SameSite: 'None',
      Secure: true,
    })
    setShow(false)
  }, [])

  const handleSubscribeSuccess = useCallback(() => {
    Cookies.set('newpopup_expires365', 'true', {
      expires: 365,
      Domain: window.location.hostname,
      SameSite: 'None',
      Secure: true,
    })
  }, [])
  useEffect(() => {
    window.addEventListener('scroll', handlePopupOpen)
    return () => window.removeEventListener('scroll', handlePopupOpen)
  }, [handlePopupOpen])
  return (
    <div id={id} className={rootClassName}>
      <div className={s.newcomerSubscribePopup__overlay}>
        <div className={s.newcomerSubscribePopup__content__wrapper}>
          <div className={s.newcomerSubscribePopup__content}>
            <button
              onClick={handlePopupClose}
              type="button"
              aria-label="close pop"
              className={s.newcomerSubscribePopup__close}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.8545 5.31015C16.4577 4.91337 15.8144 4.91338 15.4177 5.31015L11.1287 9.5991L6.8401 5.31049C6.44333 4.91372 5.80004 4.91372 5.40327 5.31049C5.0065 5.70726 5.0065 6.35055 5.40327 6.74732L9.69188 11.0359L5.403 15.3248C5.00623 15.7216 5.00623 16.3649 5.403 16.7616C5.79977 17.1584 6.44306 17.1584 6.83983 16.7616L11.1287 12.4728L15.4179 16.762C15.8147 17.1588 16.458 17.1588 16.8548 16.762C17.2515 16.3652 17.2515 15.7219 16.8548 15.3252L12.5655 11.0359L16.8545 6.74698C17.2513 6.35021 17.2513 5.70692 16.8545 5.31015ZM16.9229 6.02856C16.9229 6.22992 16.8461 6.43127 16.6925 6.5849L14.467 8.8104L12.2415 11.0359L12.2415 11.0359L16.6925 6.58493C16.8461 6.43129 16.9229 6.22993 16.9229 6.02856ZM5.35161 6.19087C5.38187 6.3354 5.4531 6.47307 5.56531 6.58528L10.016 11.0359L10.016 11.0359L5.56531 6.58524C5.45311 6.47304 5.38188 6.33539 5.35161 6.19087ZM5.34877 16.1923C5.37743 16.3415 5.44952 16.484 5.56505 16.5996C5.87232 16.9068 6.37051 16.9068 6.67779 16.5996L11.1287 12.1486L15.58 16.5999C15.8873 16.9072 16.3854 16.9072 16.6927 16.5999C16.8482 16.4445 16.925 16.2402 16.9231 16.0365C16.925 16.2402 16.8482 16.4445 16.6927 16.5999C16.3854 16.9072 15.8873 16.9072 15.58 16.5999L11.1287 12.1487L6.67779 16.5996C6.37051 16.9069 5.87232 16.9069 5.56505 16.5996C5.44952 16.4841 5.37742 16.3415 5.34877 16.1923Z"
                  fill="#333333"
                />
              </svg>
            </button>
            <div className={s.newcomerSubscribePopup__body}>
              <p
                className={s.newcomerSubscribePopup__title}
                dangerouslySetInnerHTML={{
                  __html: newcomerSubscribePopupMetafields.pop_title,
                }}
              />
              <p
                className={s.newcomerSubscribePopup__subtitle}
                dangerouslySetInnerHTML={{
                  __html: newcomerSubscribePopupMetafields.pop_desc,
                }}
              />
              <Picture
                // @ts-ignore
                source={`${newcomerSubscribePopupMetafields.img_url}`}
                loading="eager"
                className={s.newcomerSubscribePopup__image}
                imgClassName="object-cover"
              />
            </div>
            <div className={s.newcomerSubscribePopup__footer}>
              {/* @ts-ignore */}
              <SubscribeFrom
                className="w-full"
                wrapClassName="w-full flex-col flex- mb-5 "
                tipsClassName="mt-4 min-h-[46px]"
                inputClassName="mb-3 h-[46px] px-5 min-md:py-5 bg-[#f6f6f6] text-[16px] font-medium rounded-[4px] border-[0] placeholder-[#aaa] outline-none"
                buttonClassName="w-full leading-[1.2] py-[15px] text-[16px] font-semibold rounded-[4px] !bg-[#333] "
                shopCommon={shopCommon}
                btn={newcomerSubscribePopupMetafields?.submit_btn_txt}
                dealsType={newcomerSubscribePopupMetafields?.dealsType}
                onSubscribeSuccess={handleSubscribeSuccess}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewcomerSubscribePopup
