import { PageGroup } from './track'
import { languageTerritory } from '@commerce/product/use-price'
import type { Product } from '@commerce/types/product'

export const getPageGroup = ({
  page_group,
  sku,
  handle,
}: {
  page_group: PageGroup
  sku?: string
  handle?: string
}) => {
  switch (page_group) {
    case 'Product Detail Page':
      return `${page_group}_${sku}`
    case 'Activity Page':
      return `${page_group}_${handle}`
    case 'Product List Page':
      return `${page_group}_${handle}`
    default:
      return page_group
  }
}

export const getCurrencyCode = (products: Product[]) => {
  return products[0]?.price?.currencyCode
}

export const formatPrice = (
  price: number,
  currency: string | undefined,
  locale: string | undefined = 'us'
): string => {
  return price?.toLocaleString(languageTerritory(locale), {
    style: 'decimal',
    currency: currency || 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
