import { gaTrack } from './gaTrack'
import { ClickParameters } from '../track'
import { getPageGroup } from '../utils'
import { ClickMap } from '../const'

export const click = ({
  event,
  page_group,
  sku,
  handle,
  position,
  extra = {},
}: ClickParameters) => {
  gaTrack({
    event: 'uaEvent',
    eventCategory: event,
    eventAction: position,
    eventLabel: sku,
    nonInteraction: true,
  })

  gaTrack({ event_parameters: null })
  gaTrack({
    event: 'ga4Event',
    event_name: ClickMap[position as keyof typeof ClickMap],
    event_parameters: {
      page_group: getPageGroup({ page_group, handle, sku }) || '',
      ...extra,
    },
  })
}
