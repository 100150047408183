import sha256 from 'crypto-js/sha256'
import encHex from 'crypto-js/enc-hex'
import { gaTrack } from './gaTrack'
import { SubscribeParameters } from '../track'

export const subscribe = ({
  event = 'subscribe',
  email,
  page_group,
  deals_type,
  position,
}: SubscribeParameters) => {
  const hash = sha256(email.toLowerCase())
  const hashEmail = hash.toString(encHex)
  gaTrack({ event_parameters: null })
  gaTrack({
    subscribe_hashed_email: hashEmail, //推订阅的加密邮箱，加密算法sha256-20230726新增
  }) //标黄代码须在下方代码前
  gaTrack({
    event: 'ga4Event',
    event_name: event,
    event_parameters: {
      page_group:
        page_group || deals_type || (position === 'footer' ? 'footer' : ''), // Simplified conditional logic
      position,
    },
  })

  gaTrack({
    event: 'uaEvent',
    eventCategory: 'subscribe',
    eventAction:
      page_group || deals_type || (position === 'footer' ? 'footer' : ''), // Simplified conditional logic
    eventLabel: position,
    nonInteraction: false,
  })
}
