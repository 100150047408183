import { gaTrack } from './gaTrack'
import { SelectParameters, ItemProduct, SelectProduct } from '../track'
import { getPageGroup, formatPrice, getCurrencyCode } from '../utils'
import { BRAND } from '@shopify/const'
import { ClickMap } from '../const'

export const selectItem = ({
  event,
  page_group,
  product,
  sku,
  handle,
  position,
  locale,
  item_list_name,
  extra = {},
}: SelectParameters) => {
  const products = Array.isArray(product) ? product : [product]
  const currencyCode = getCurrencyCode(products)
  let totalProductPrice = 0
  let item: ItemProduct = {}
  let selectProduct: SelectProduct = {}

  const variant = product?.variants[0]
  const price = product?.isFreeGift
    ? 0
    : variant?.price || product?.price?.value
  const priceWithOutSymbol: string = formatPrice(price, currencyCode, locale)

  totalProductPrice += price

  selectProduct = {
    name: product?.name,
    id: variant?.sku,
    category: '',
    categoryName: product?.productType || '',
    brand: BRAND,
    variant: variant?.name,
    price: priceWithOutSymbol,
    quantity: 1,
  }
  item = {
    id: variant?.sku,
    name: product?.name,
    brand: BRAND,
    category: '',
    variant: variant?.name,
    price: priceWithOutSymbol,
    quantity: 1,
  }

  gaTrack({ event_parameters: null })
  gaTrack({
    event: 'ga4Event',
    event_name: ClickMap[event as keyof typeof ClickMap],
    event_parameters: {
      page_group: getPageGroup({ page_group, sku, handle }),
      position, //位置标记。如果是listing的底部悬浮按钮，传Product Detail Page_Sticky;如果是listing的首屏按钮，传Product Detail Page_Checkout；如果是侧边栏，传Cart Pop Up；如果是活动页/首页，则传该模块的小标题（该模块有ref参数则传ref参数）
      currency: currencyCode,
      value: formatPrice(totalProductPrice, currencyCode, locale), //折后总价
      item_list_name: `${item_list_name}${handle}`,
      ...item,
    },
  })
}
