import { gaTrack } from './gaTrack'
import { CommerceParameters, EcommerceItem, EcommerceProduct } from '../track'
import { EcommerceGa3Map, EcommerceGa4Map } from '../const'
import { BRAND } from '@shopify/const'
import { getPageGroup, formatPrice, getCurrencyCode } from '../utils'
import { getCouponFromVariant } from '@commerce/product/use-coupon'

export const commerce = ({
  event,
  product,
  page_group,
  handle,
  sku,
  login_status = 'non-member',
  position,
  shopCommon,
  item_list_name,
  locale,
  applyCoupon = true,
  extra_info,
}: CommerceParameters) => {
  const products = Array.isArray(product) ? product : [product]
  const currencyCode = getCurrencyCode(products)
  const items: EcommerceItem[] = []
  const ecommerceProducts: EcommerceProduct[] = []
  let totalProductPrice = 0

  products.forEach((p, idx) => {
    const variant =
      p.variants.length === 1
        ? p.variants[0]
        : idx === 0
        ? p.variants.find((v) => v.sku === sku)
        : p.variants[0]
    const price = p.isFreeGift ? 0 : variant?.price || p.price.value
    const priceWithOutSymbol: string = formatPrice(price, currencyCode, locale)

    totalProductPrice += price

    const coupon = applyCoupon && getCouponFromVariant(variant)

    ecommerceProducts.push({
      name: p.name,
      id: variant?.sku,
      category: '',
      categoryName: p.productType || '',
      brand: BRAND,
      variant: variant?.name,
      price: priceWithOutSymbol,
      coupon: coupon?.title || '', //折扣code
      discount: coupon?.value_style
        ? `${
            coupon?.value_type === 'percentage'
              ? coupon?.value_style.slice(1) + '%'
              : coupon?.value_style
          } ${shopCommon?.off || 'OFF'}`
        : '', //折扣金额
      quantity: 1,
    })
    items.push({
      item_id: variant?.sku,
      item_name: p.name,
      item_brand: BRAND,
      item_category: '',
      item_variant: variant?.name,
      price: priceWithOutSymbol,
      // coupon: coupon?.title || '', //折扣code
      // discount: coupon?.value_style
      //   ? `${
      //       coupon?.value_type === 'percentage'
      //         ? coupon?.value_style.slice(1) + '%'
      //         : coupon?.value_style
      //     } ${shopCommon?.off || 'OFF'}`
      //   : '', //折扣金额
      quantity: 1,
    })
  })

  const ecommerce = {
    currencyCode,
    add: {
      products: ecommerceProducts,
    },
  }

  const event_parameters = {
    page_group: getPageGroup({ page_group, sku, handle }),
    position, //位置标记。如果是listing的底部悬浮按钮，传Product Detail Page_Sticky;如果是listing的首屏按钮，传Product Detail Page_Checkout；如果是侧边栏，传Cart Pop Up；如果是活动页/首页，则传该模块的小标题（该模块有ref参数则传ref参数）
    info: login_status, //用户登录状态member、non-member（GTM上线login_status事件参数后删掉或可覆盖）
    currency: currencyCode,
    value: formatPrice(totalProductPrice, currencyCode, locale), //折后总价
    items,
    extra_info,
  }

  if (item_list_name) {
    // @ts-ignore
    ecommerce.item_list_name = `${item_list_name}${handle}`
    // @ts-ignore
    event_parameters.item_list_name = `${item_list_name}${handle}`
  }

  gaTrack({
    event: 'eeEvent',
    eventCategory: 'EnhancedEcommerce',
    eventAction: EcommerceGa3Map[event],
    eventLabel: sku,
    nonInteraction: false,
    ecommerce,
  })

  gaTrack({ event_parameters: null })
  gaTrack({
    event: 'ga4Event',
    event_name: EcommerceGa4Map[event], //如果是加购,则传add_to_cart
    event_parameters,
  })
}
