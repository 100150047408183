import { subscribe } from './ga/subscribe'
import { register } from './ga/register'
import { commerce } from './ga/commerce'
import { metaCommerce } from './meta/commerce'
import { click } from './ga/click'
import { viewList } from './ga/viewItemList'
import { selectItem } from './ga/selectItem'
import { viewItem } from './ga/viewItem'

export const track = (data: any) => {
  switch (data?.event) {
    case 'subscribe':
      subscribe(data)
      break
    case 'register':
      register(data)
      break
    case 'buynow':
      commerce(data)
      metaCommerce(data)
      break
    case 'add2cart':
      commerce(data)
      metaCommerce(data)
      break
    case 'click':
      click(data)
      break
    case 'view_item_list':
      viewList(data)
      break
    case 'select_item':
      selectItem(data)
      break
    case 'viewitem':
      viewItem(data)
      break
  }
}
