// start_ai_generated
import cn from 'classnames'
import Button from '@components/home/Button'
import { Text } from '@components/ui'
import { useState, useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { regExp } from '@lib/utils/regExp'
import { getAdCookie } from '@lib/utils/cookies'
import { I18N_STORE_DOMAIN, BRAND } from '@shopify/const'
import Icon from '@components/icons'
import fetcher from '@lib/fetcher'
import { track } from '@lib/track'

const SubscribeFrom = ({
  btn,
  placeholder,
  className,
  wrapClassName,
  inputClassName,
  tipsClassName,
  inputConfig = {},
  buttonClassName,
  policyClassName = '',
  buttonType = 'custom',
  buttonConfig = {},
  shopCommon,
  successInfo,
  dealsType,
  onSubscribeSuccess,
  subBrandType,
}) => {
  const { locale } = useRouter()
  const [email, setEmail] = useState('')
  const [tips, setTips] = useState()
  const [tipsType, setTipsType] = useState('error')
  const [policy, setPolicy] = useState(false)
  const [loading, setLoading] = useState(false)
  // 使用 useRef 避免在每次渲染时创建新的 timer
  const timer = useRef(null) // 修改为 useRef

  const IconMap = {
    error: 'tipsError',
    success: 'tipsCorrect',
  }

  // 使用 useCallback 避免在每次渲染时创建新的 showTips 函数
  const showTips = useCallback(
    ({ type, info, status }) => {
      // 添加 useCallback
      setTips(info || shopCommon?.[type])
      if (timer.current) clearTimeout(timer.current) // 使用 current 访问 timer
      timer.current = setTimeout(() => {
        // 使用 current 设置 timer
        setTips('')
      }, 5000)
      if (status === 'success') {
        setTipsType('success')
        setEmail('')
        setPolicy(false)
      } else {
        setTipsType('error')
      }
    },
    [shopCommon]
  ) // 添加依赖数组

  // 使用 useCallback 避免在每次渲染时创建新的 handleActivities 函数
  const handleActivities = useCallback(async () => {
    // 添加 useCallback
    setLoading(true)
    const { register_source } = getAdCookie()
    let url = '/api/multipass/rainbowbridge/activities'
    const res = await fetcher({
      action: 'activity',
      needRecaptcha: true,
      locale,
      url,
      method: 'POST',
      body: {
        email,
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: dealsType,
        sub_brand_type: subBrandType,
      },
    })
    setLoading(false)
    if (res.status > 100 && res.status < 300) {
      onSubscribeSuccess && onSubscribeSuccess(email)
      return showTips({
        type: 'info_seccess',
        info: successInfo,
        status: 'success',
      })
    }
    showTips({
      info:
        res.error || 'An unknown problem has occurred. Please try again later',
      status: 'error',
    })
  }, [locale, email, dealsType, successInfo, showTips, onSubscribeSuccess])

  const handleSubscribe = useCallback(async () => {
    setLoading(true)
    const { register_source } = getAdCookie()
    let url = '/api/multipass/subscribe/subscribe_emails'
    const res = await fetcher({
      locale,
      url,
      method: 'POST',
      body: {
        email,
        register_source,
        brand: BRAND,
      },
    })
    setLoading(false)
    if (res?.errors) {
      showTips({
        info:
          res.errors ||
          'An unknown problem has occurred. Please try again later',
        status: 'error',
      })
    } else {
      if (res?.status === 201) {
        onSubscribeSuccess && onSubscribeSuccess()
        showTips({
          type: 'info_seccess',
          info: successInfo,
          status: 'success',
        })
        // 补充埋点
        track({
          event: 'subscribe',
          email,
          page_group: 'footer',
          position: 'lastbanner',
        })
      } else {
        if (res?.message) {
          showTips({
            info: res.message,
            status: 'error',
          })
        } else {
          showTips({
            info: 'An unknown problem has occurred. Please try again later',
            status: 'error',
          })
        }
      }
    }
  }, [locale, email, successInfo, showTips, onSubscribeSuccess]) // 添加依赖数组

  // 使用 useCallback 避免在每次渲染时创建新的 handleSignup 函数
  const handleSignup = useCallback(async () => {
    // 添加 useCallback
    return dealsType ? handleActivities() : handleSubscribe()
  }, [dealsType, handleActivities, handleSubscribe]) // 添加依赖数组

  // 使用 useCallback 避免在每次渲染时创建新的 handleSubmit 函数
  const handleSubmit = useCallback(() => {
    // 添加 useCallback
    if (!email) {
      return showTips({ type: 'info_fill_email' })
    }
    if (!regExp.email.test(email)) {
      return showTips({ type: 'info_err_email' })
    }
    if (!policy) {
      return showTips({ type: 'info_select_policy' })
    }
    handleSignup()
  }, [email, policy, handleSignup, showTips]) // 添加依赖数组

  // 使用 useEffect 清除 timer，避免内存泄漏
  useEffect(() => {
    // 添加 useEffect
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, []) // 空依赖数组表示仅在组件卸载时执行

  return (
    <div className={cn('mx-auto', className)}>
      <div className={cn('mx-auto mb-4 w-[300px]', wrapClassName)}>
        <input
          type="email"
          placeholder={placeholder || shopCommon?.use_placeholders}
          className={cn(
            'mb-2 w-full border p-2',
            'md:text-center',
            inputClassName
          )}
          {...inputConfig}
          value={email}
          onChange={(e) => setEmail(e.target.value?.trim())}
        />
        {buttonType === 'custom' && (
          <button
            className={cn('w-full bg-[#333] p-2 text-white', buttonClassName, {
              ['pointer-events-none cursor-not-allowed !bg-[#ddd] !text-white']:
                loading,
            })}
            {...buttonConfig}
            disabled={loading}
            onClick={handleSubmit}
          >
            {btn || shopCommon?.submit_btn_txt}
          </button>
        )}
        {buttonType !== 'custom' && (
          <Button
            variant={buttonType}
            className={cn(buttonClassName)}
            {...buttonConfig}
            disabled={loading}
            onClick={handleSubmit}
          >
            {btn || shopCommon?.submit_btn_txt}
          </Button>
        )}
      </div>
      <div
        className={cn(
          'user-select-none mb-4 flex cursor-pointer justify-center gap-x-1 md:gap-x-2',
          policyClassName
        )}
        onClick={() => {
          setPolicy(!policy)
        }}
      >
        <div
          className={cn(
            'relative flex h-[18px] w-[18px] items-center justify-center rounded-[2px] border border-[#d2d2d2]',
            {
              '!border-[#005D8E] bg-[#005D8E]': policy,
            }
          )}
        >
          <Icon iconKey="checkBoxCorrect"></Icon>
        </div>
        <Text className="" html={shopCommon?.policy_text}></Text>
      </div>
      <div className={cn(tipsClassName)}>
        {tips && (
          <p className="flex items-center justify-center gap-x-1">
            <Icon iconKey={IconMap[tipsType]}></Icon>
            <span>{tips}</span>
          </p>
        )}
      </div>
    </div>
  )
}

export default SubscribeFrom
// end_ai_generated
