export const metaTrack = (event: string, data?: object): void => {
  if (typeof window === 'undefined') {
    return
  }
  if (typeof window.fbq !== 'undefined') {
    try {
      if (data) {
        window.fbq('track', event, data)
      } else {
        window.fbq('track', event)
      }
    } catch (error) {
      console.log('fbqTrack error', error)
    }
  }
}
