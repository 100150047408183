export const gaTrack = (obj: object): void => {
  if (typeof window === 'undefined') {
    return
  }
  window.dataLayer = window?.dataLayer || []
  try {
    window?.dataLayer?.push(obj || {})
  } catch (error) {
    console.log('gaTrack error', error)
  }
}
