import { gaTrack } from './gaTrack'
import { SignupParameters } from '../track'

export const register = ({
  page_group,
  button_name,
  position,
}: SignupParameters) => {
  gaTrack({
    event: 'uaEvent',
    eventCategory: 'entry_for_register',
    eventAction: page_group || '',
    eventLabel: position,
    nonInteraction: false,
  })

  gaTrack({ event_parameters: null })
  gaTrack({
    event: 'ga4Event',
    event_name: 'entry_for_register',
    event_parameters: {
      page_group: page_group || '',
      button_name,
      position,
    },
  })
}
